import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Accessible web forms`}</h1>
    <p>{`Let’s take a dive into using forms in our web applications.`}</p>
    <h2><HashLink id="anatomy" to="/form-a11y#anatomy" mdxType="HashLink">{`Anatomy of an HTML form`}</HashLink></h2>
    <h3><HashLink id="input-purpose" to="/form-a11y#input-purpose" mdxType="HashLink">{`Input purpose`}</HashLink></h3>
    <p>{`Step 1 to making form input purpose clear is to use adequate instruction and labelling. The `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#labels-or-instructions"
      }}>{`success criterion 3.3.2 for Level A compliance`}</a>{` regarding labels:`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://www.w3.org/TR/WCAG21/#dfn-labels"
        }}>{`Labels`}</a>{` or instructions are provided when content requires user input.`}</p>
    </blockquote>
    <p>{`Form control labels are mandatory. They give people context around the data they are submitting. In the case where having a label is unnecessary for sighted users, we should include a label even if it’s not rendered in the UI. `}</p>
    <p>{`An additional benefit of using labels is that users can click on labels to focus the associated form control!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<label for=”username”>Click me</label>
<input id=”username” type=”text”>
`}</code></pre>
    <p>{`Assistive technologies are able to identify labels and present the label text to the user in a familiar manner. Screen reader users generally navigate through a form using the Tab key to jump from form control to form control. Associated form labels are read for each form control when the user navigates to them. Any non-label text content between the form controls is usually skipped over. Be sure to include important cues or instructions in associated labels or at the beginning of the form.`}</p>
    <p>{`In some situations, form controls cannot be labeled explicitly. For example, a content author might not know the ID of a form field generated by a script, or a script might not add an ID to a dynamically created element. In this case, the label element can be used as a container for both the form control and the label text so that the two are associated implicitly. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<label>
  First name:
  <input type="text" name="firstname">
</label>
`}</code></pre>
    <p>{`Generally, explicit labels are better supported by assistive technology.`}</p>
    <p>{`One of the following three techniques should be used when a visible label is not an option:`}</p>
    <ul>
      <li parentName="ul">{`Hide the label element with CSS.`}</li>
      <li parentName="ul">{`Use a title attribute. If a form field has a title attribute, but no label, the screen reader will read the title as if it were a label. This technique will also cause a tooltip to appear when the user hovers over the field with a mouse, which could be distracting for some users.`}</li>
      <li parentName="ul">{`Use an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{`. Unlike `}<inlineCode parentName="li">{`aria-labelledby`}</inlineCode>{` which must reference another element, `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` contains the label text directly. As with `}<inlineCode parentName="li">{`aria-labelledby`}</inlineCode>{`, `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` will override any associated label elements.`}</li>
    </ul>
    <p>{`Only one of these recommendations should be implemented. Using two or more together (e.g., a hidden label and a duplicate title attribute) can cause information to be repeated by a screen reader. Also note that placeholder text (e.g., `}<inlineCode parentName="p">{`<input type="text" placeholder="Search this site">`}</inlineCode>{`) is not a suitable label and should never be used in place of the above techniques.`}</p>
    <p>{`In addition to the situations where a visible label element is not an option, there are other times when the label element falls short. It cannot be used to provide multiple labels for a single form control or to associate a single label with multiple form controls. The majority of these labeling limitations can be overcome with three ARIA properties (`}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{`, `}<inlineCode parentName="p">{`aria-describedby`}</inlineCode>{`, and `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{`).`}</p>
    <p><strong parentName="p"><inlineCode parentName="strong">{`aria-labelledby`}</inlineCode></strong></p>
    <p>{`The `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` attribute solves the problem of not being able to establish a 1-to-many or many-to-1 relationship between input and label elements. The value of `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` should be one or more element IDs of the label element(s). If a control has both an associated label and `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{`, the referenced `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` text will override and be read instead of the associated label. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h3 id=”label”>Women’s Outerwear</h3>

<button id=”button” aria-labelledby=”label button”>
  Shop now
</button>
`}</code></pre>
    <p>{`As a general rule, if a single label is present for a single control, the label element should be used to reference it. These labels provide additional functionality - clicking on them sets focus to or activates the control. This functionality is not available when using `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{`.`}</p>
    <p><strong parentName="p"><inlineCode parentName="strong">{`aria-describedby`}</inlineCode></strong></p>
    <p>{`The `}<inlineCode parentName="p">{`aria-describedby`}</inlineCode>{` attribute is great for when a form includes information that isn't exactly a label but is important enough to be read by a screen reader when navigating to the form control.`}</p>
    <p>{`While the `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` attribute overrides the label, `}<inlineCode parentName="p">{`aria-describedby`}</inlineCode>{` does not. This means that `}<inlineCode parentName="p">{`aria-describedby`}</inlineCode>{` should only be used in addition to a label, not instead of one.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`aria-describedby`}</inlineCode>{` attribute can also be used to reference descriptions that appear as 'tooltips'. As a side note, tooltips should become visible to sighted keyboard users when the control has keyboard focus, not just when the user hovers or clicks with a mouse.`}</p>
    <p>{`Outside of labels, how can we ensure the purpose of our input controls is effectively conveyed?`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#identify-input-purpose"
      }}>{`success criterion 1.3.5 for Level AA compliance`}</a>{` regarding input purpose:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The purpose of each input field collecting information about the user can be programmatically determined when:`}</p>
    </blockquote>
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul">{`The input field serves a purpose identified in the `}<a parentName="li" {...{
            "href": "https://www.w3.org/TR/WCAG21/#input-purposes"
          }}>{`Input Purposes for User Interface Components section`}</a>{`; and`}</li>
        <li parentName="ul">{`The content is implemented using technologies with support for identifying the expected meaning for form input data.`}</li>
      </ul>
    </blockquote>
    <p>{`Translation: every user should be able to discern the purpose of a form input. If we make the input purpose clear to the user agent, it allows folks accessing the user agent via different modalities to understand what’s needed to correctly input the data.`}</p>
    <p>{`It’s important to note the difference between `}<em parentName="p">{`intent`}</em>{` and `}<em parentName="p">{`purpose`}</em>{`. If you have an address input the user may be able to discern that the intent is for them to enter an address but what will the address data be used for? Is this a shipping address? Billing address?`}</p>
    <p>{`So how do we satisfy this requirement? As an example, say we have an input to capture a user’s name. The `}<inlineCode parentName="p">{`autocomplete`}</inlineCode>{` attribute can be used to add another layer of specificity to the input purpose. Attribute values like “name”, “given-name”, “family-name”, “username” and “nickname” all make clear the reason for the data input.`}</p>
    <p>{`A secondary benefit of using an `}<inlineCode parentName="p">{`autocomplete`}</inlineCode>{` attribute to fulfill this success criterion is that it allows for input suggestions based on the user’s past form submissions. This can be especially beneficial for users who live with language and memory related disabilities or disabilities that affect executive function and decision-making.`}</p>
    <p>{`Additionally, when input purpose is clearly defined, assistive technology can add icons to input fields to communicate the purpose of the fields visually to help people with cerebral palsy, stroke, head injury, motor neuron disease or learning disability who sometimes prefer images for communication.`}</p>
    <p>{`How else can we ensure that the purpose of an input is programmatically understood? According to `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/identify-input-purpose.html"
      }}>{`supporting documentation for success criterion 1.3.5`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`When the user agent and assistive technology support for other metadata formats matures, metadata schemes like the `}<a parentName="p" {...{
          "href": "https://w3c.github.io/personalization-semantics/content/"
        }}>{`Personalization Semantics Content Module`}</a>{` may be used in addition or instead of the HTML autocomplete attribute to identify the purpose of input fields.`}</p>
    </blockquote>
    <p>{`At the time of writing, it looks like the Personalization Semantics Content Module is still a draft.`}</p>
    <h3><HashLink id="controls" to="/form-a11y#controls" mdxType="HashLink">{`Form controls`}</HashLink></h3>
    <p>{`WebAIM offers a `}<a parentName="p" {...{
        "href": "https://webaim.org/techniques/forms/controls"
      }}>{`list of form control examples`}</a>{` which are nice to test with a screen reader for comparison purposes. This list includes text inputs, textareas, checkboxes, radio buttons, select menus, buttons, image buttons, and JavaScript jump menus.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`aria-required`}</inlineCode>{` attribute can be used to identify required fields to screen reader users, especially if the label text does not indicate this or if color-alone or an asterisk are used to identify the required fields.`}</p>
    <blockquote>
      <p parentName="blockquote">{`HTML5 now has the required attribute, but aria-required is still useful for user agents that do not yet support HTML5.`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-required_attribute"
      }}>{`Source MDN article`}</a></p>
    <p>{`Groupings of form controls, typically groups of related checkboxes and radio buttons, sometimes require a higher level description (such as "Shipping Method" for a group of shipping option radio buttons). This descriptive text can be associated to the group of form controls using `}<inlineCode parentName="p">{`fieldset`}</inlineCode>{` and `}<inlineCode parentName="p">{`legend`}</inlineCode>{`. The `}<inlineCode parentName="p">{`fieldset`}</inlineCode>{` identifies the entire grouping and `}<inlineCode parentName="p">{`legend`}</inlineCode>{` identifies the grouping's descriptive text. Using `}<inlineCode parentName="p">{`fieldset`}</inlineCode>{` and `}<inlineCode parentName="p">{`legend`}</inlineCode>{` ensures that the text description is read to screen reader users when the grouping is navigated to.`}</p>
    <p>{`In addition to grouping our controls appropriately, we should be using intuitive controls in our forms. For example, it is recommended that multiple select menus be avoided. Not all browsers provide intuitive keyboard navigation for multiple select menus. Many users do not know to use CTRL/Command or Shift + click to select multiple items. Typically, a set of check box options can provide similar, yet more accessible functionality.`}</p>
    <p>{`How can we ensure our custom form controls are accessible?`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#name-role-value"
      }}>{`success criterion 4.1.2 for Level A compliance`}</a>{` regarding name, role and value:`}</p>
    <blockquote>
      <p parentName="blockquote">{`For all user interface components (including but not limited to: form elements, links and components generated by scripts), the name and role can be programmatically determined; states, properties, and values that can be set by the user can be programmatically set; and notification of changes to these items is available to user agents, including assistive technologies.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`This success criterion is primarily for Web authors who develop or script their own user interface components. For example, standard HTML controls already meet this success criterion when used according to specification.`}</p>
    </blockquote>
    <p>{`To better understand this success criterion it’s helpful to use examples. First off, when we develop custom controls we need to make sure that focus state and notification regarding change of focus is properly discoverable by user agents and assistive technology.`}</p>
    <p>{`Another example would be that we need to make sure the selected state of a custom checkbox or radio item is properly implemented if we aren’t using the standard HTML controls. Similarly, the expand and collapse states should be apparent when building custom tree or list nodes. `}</p>
    <p>{`Simply put, we need to ensure the custom components we use adhere to the same contract as their corresponding native HTML elements so that people using assistive technology can predictably interact with our applications.`}</p>
    <h2><HashLink id="submission" to="/form-a11y#submission" mdxType="HashLink">{`Form submission`}</HashLink></h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#error-prevention-legal-financial-data"
      }}>{`success criterion 3.3.4 for level AA compliance`}</a>{` regarding data submission:`}</p>
    <blockquote>
      <p parentName="blockquote">{`For Web pages that cause legal commitments or financial transactions for the user to occur, that modify or delete user-controllable data in data storage systems, or that submit user test responses, at least one of the following is true:`}</p>
    </blockquote>
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul">{`Reversible: Submissions are reversible.`}</li>
        <li parentName="ul">{`Checked: Data entered by the user is checked for input errors and the user is provided an opportunity to correct them.`}</li>
        <li parentName="ul">{`Confirmed: A mechanism is available for reviewing, confirming, and correcting information before finalizing the submission.`}</li>
      </ul>
    </blockquote>
    <p>{`The more strict `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#error-prevention-all"
      }}>{`3.3.6 AAA standard`}</a>{` is similar but has a wider scope of applicability. One of the above needs to be true for “Web pages that require the user to submit information”.`}</p>
    <p>{`Some folks may be more likely to make mistakes when entering information in a form control. For example, people with motor skill impairment may hit keys by mistake and those with reading disabilities may interchange numbers and letters.`}</p>
    <p>{`No matter what we do, mistakes will be made. How can we handle them?`}</p>
    <p>{`There is a 3-step process for ensuring usable and accessible error recovery if either client-side or server-side validation detects errors in the form:`}</p>
    <ol>
      <li parentName="ol">{`Alert the user to the presence of the error in an apparent and accessible manner.`}</li>
      <li parentName="ol">{`Allow the user to easily access the form controls that need to be modified.`}</li>
      <li parentName="ol">{`Allow resubmission and revalidation of the form.`}</li>
    </ol>
    <p>{`Here are a few mutually exclusive strategies we can use to satisfy these requirements.`}</p>
    <h3><HashLink id="alert-error" to="/form-a11y#alert-error" mdxType="HashLink">{`Alert the user of an error then focus the respective form control`}</HashLink></h3>
    <p><strong parentName="p">{`Advantage:`}</strong>{` users are informed of errors immediately and can then easily resolve the issue directly. `}</p>
    <p><strong parentName="p">{`Disadvantage:`}</strong>{` only one error is indicated and addressed at a time.`}</p>
    <h3><HashLink id="list-errors" to="/form-a11y#list-errors" mdxType="HashLink">{`Render all form errors on top of the form`}</HashLink></h3>
    <p>{`We must provide a mechanism to quickly give our users access to the form control that must be remedied once the user has been presented with the associated error message. `}</p>
    <p>{`We can provide a link within the error message that will set focus to the appropriate form control. While this can be done using client-side scripting, it’s typically easier and safer to simply provide a link that will set focus to the form control.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<a href="#input-id">Click me to focus on the input</a>
<!-- ... -->
<input id="input-id" type="text" aria-label="Test input">
`}</code></pre>
    <p><strong parentName="p">{`Advantage:`}</strong>{` all errors are presented together. `}</p>
    <p><strong parentName="p">{`Disadvantage:`}</strong>{` if there are multiple errors, it can be difficult for the user to remember, find, and address all of them.`}</p>
    <h3><HashLink id="inline-error" to="/form-a11y#inline-error" mdxType="HashLink">{`Inline error designation`}</HashLink></h3>
    <p>{`This designation can be accomplished via labeling or perhaps `}<inlineCode parentName="p">{`aria-describedby`}</inlineCode>{`. It may be helpful to set focus to the first control that needs attention.`}</p>
    <p><strong parentName="p">{`Advantage:`}</strong>{` errors appear in context with their respective controls. `}</p>
    <p><strong parentName="p">{`Disadvantage:`}</strong>{` the user must visually scan or navigate through the form to discover the invalid controls and their respective error messages which can take some time.`}</p>
    <h3><HashLink id="error-strategy" to="/form-a11y#error-strategy" mdxType="HashLink">{`So, which strategy should we use?`}</HashLink></h3>
    <p>{`No one approach is best for accessibility, but there might be an optimal strategy based on the content, layout, and complexity of the forms they are applied to. `}</p>
    <p>{`However, regardless of the mechanism used to identify and recover from form errors, `}<inlineCode parentName="p">{`aria-invalid="true"`}</inlineCode>{` should generally be set on each invalid form control. This attribute causes screen readers to identify the control as being "invalid" or in need of attention.`}</p>
    <h2><HashLink id="conclusion" to="/form-a11y#conclusion" mdxType="HashLink">{`If you walk away with nothing else`}</HashLink></h2>
    <p>{`It’s important that we build our forms so that it’s as easy as possible for all users to successfully submit information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      